.copyright {
  position: absolute;
  bottom: 1em;
  background: black;
  color: white;
  font-size: 0.8em;
}
.copyright a {
  color: white;
  text-decoration: none;
}
